import React from 'react'
import { Container } from 'react-bootstrap'
import Footer from '../../sections/FooterBlock/Footer'
import Header from '../../sections/Header/Header'
import ReadyBlock from '../../sections/ReadyBlock/ReadyBlock'

const Privacy = (props) => {
    const { onSubmit ,eventTrack} = props;
    return (
        <>
            <Container fluid>
                <div className='container'>
                    <Header />

                    <div className='page_main'>
                        <h1 className='page_headding'>Privacy Policy</h1>


                        <div className='page_inner_main'>
                            <div className=''>
                                <h1 className='page_subheadding'>Agreement and Acknowledgment</h1>
                                <p className='page_para'>
                                    loanfy.net is proposed to be used just by individuals who are over the legal age of 18 and individuals who are legal US citizens. We ask you to take a couple of minutes to survey these terms expressed in this present Privacy Policy. By consenting to use loanfy.net 's services you are consenting to the policy expressed here. On the off chance that you aren't in agreement with the terms of this policy or as of now are under the legal age of eighteen (18) or not a US resident at that point please shun from using our services.
                                </p>

                            </div>

                            <div className='page_inner_child'>
                                <h1 className='page_subheadding'>Change notification to loanfy.net Privacy practices</h1>
                                <p className='page_para'>We unequivocally claim our authority to refresh our Privacy Policy keeping in mind the end goal to mirror these changes we need to make to our current information practices. If changes are made to loanfy.net information practices this privacy policy will be reconsidered. We encourage you to dependably audit and read our privacy policy each time you visit this site or choose to use our services.</p>
                            </div>

                            <div className='page_inner_child'>
                                <h1 className='page_subheadding'>Information We Collect</h1>
                                <p className='page_para'>
                                    In the course of using this Website, we may collect personal information and data to (i) present you with financial products and/or other offerings that you seek; (ii) present the contents of this Website to you; (iii) deliver services that you have requested; (iv) communicate with you via telephone, email, postal mail, and/or text message from us, our marketing partners, and/or other third parties; (v) display advertising tailored to you interests or background; (iv) perform data and statistical analysis from your use of this Website; (v) enforce our Terms and Conditions; and (vi) perform any other functions otherwise described to you when you provide us with your information.. Any collection of personal information or data through this Website is performed with your consent..
                                </p>
                                <p className='page_para'>
                                    <b>Personally Identifiable Information.</b> We may collect Personally Identifiable Information ("PII") that you directly provide to us that can identify you, including, but not limited to, your first and last name, physical address, telephone number, cellular number, social security number, email address, date of birth, driver’s license number, state of residency and information pertaining to your bank account.
                                </p>
                            </div>

                            <div className='page_inner_child'>
                                <h1 className='page_subheadding'>Loan Acceptance Policy & Rates</h1>
                                <p className='page_para'>
                                    <b>Non-Personally Identifiable Information.</b> We also collect information automatically as you navigate through this Website. We use log files and cookies to collect Non-Personally Identifiable Information ("Non-PII") including, but not limited to, the IP address of the computer or mobile device making the request, browser type and the times you access this Website and usage details. Please note that IP addresses are not usually associated with your personal computer, but with a portal through which you accessed the Internet. Consequently, IP addresses are not linked to personally identifiable information.
                                </p>
                                <p className='page_para'>
                                    <b>Cookies.</b> As you navigate through and interact with this Website, we may use automatic data collection technologies ("Cookies") to collect certain information, including but not limited to, information about your equipment, browsing actions and patterns, operating system, internet connection and location data. The information we collect automatically may include personal information, and we may maintain it or compound it with personal information we collect in other ways or receive from third parties. Cookies help us improve this Website so we are able to deliver a better and more personalized experience by reducing unwanted information that you might otherwise receive.
                                </p>
                            </div>

                            <div className='page_inner_child'>
                                <h1 className='page_subheadding'>How We Use Your Information</h1>
                                <p className='page_para'>We use the information that we collect through your interaction with this Website as well as information that you provide to us, including personal information. This information is used (i) to present the contents of this Website to you; (ii) to deliver services that you have requested; (iii) to determine whether to extend an offer to become an Affiliate or Advertiser in our Network; (iv) to communicate with you via telephone, email, postal mail, and/or text message from us, our marketing partners, or other third parties; (v) for display advertising tailored to your interests or background; (vi) perform data and statistical analysis from your use of this Website; (vii) enforce our Terms and Condition; and (viii) to perform any other functions otherwise described to you when you provide us with your information.
                                </p>
                            </div>

                            <div className='page_inner_child'>
                                <h1 className='page_subheadding'>Disclosing Your Information</h1>
                                <p className='page_para'>
                                    As a general rule, we will not share, sell or otherwise distribute your personal information without your consent. However, we may disclose personal information that we collect or you provide as described in this Policy (i) to fulfill the purpose for which you provide your information; (ii) for any other purpose disclosed by us when you provide us your information; (iii) to third party service providers who assist us in operating this Website, conducting our business or serving our users, so long as such information is kept confidential; (iv) with affiliated entities; (v) to comply with any court order, law, legal process, and/or government or regulatory request; (vi) as part of a liquidation or bankruptcy proceeding, to a buyer or other successor in the event of a merger, reorganization, dissolution or divestiture of some or all of our assets.
                                </p>
                            </div>

                            <div className='page_inner_child'>
                                <h1 className='page_subheadding'>Data Retention and Security</h1>
                                <p className='page_para'>
                                    By accessing and navigating through this Website, you hereby acknowledge and agree that loanfy.net may retain your information indefinitely. Please note that any transmission of personal information is at your own risk. <b>Commitment to Online Security.</b> loanfy.net uses reasonable efforts to protect the security of your personal information and encrypt sensitive information such as your social security number, driver license number and bank account information. Although we strive to provide reasonable security measures for your personal information that we process and maintain, we cannot guarantee the security of your personal information transmitted to and through this Website. <b>No Liability for Acts of Third Parties.</b> loanfy.net will exercise all reasonable efforts to safeguard the confidentiality of your personal information. However, transmissions protected by industry standard security technology and implemented by human beings cannot be made absolutely secure. Consequently, loanfy.net shall not be liable for unauthorized disclosure of personal information due to no fault of loanfy.net including, but not limited to, errors in transmission and unauthorized acts of third parties.
                                </p>
                            </div>

                            <div className='page_inner_child'>
                                <h1 className='page_subheadding'>Opt-out</h1>
                                <p className='page_para'>
                                We may collect information about you particularly your email address from sources other than you, such as from social media websites (i.e. Facebook, LinkedIn, Twitter or others), blogs, other users and our business partners. If you do not wish to receive email offers or newsletters from us, you can opt out of receiving email information from us (other than emails related to the completion of your registration, correction of user data, change of password and other similar communications essential to your transactions through our Services) by using the unsubscribe process at the bottom of the email.
                                </p>
                            </div>

                            <div className='page_inner_child'>
                                <h1 className='page_subheadding'>LINKS TO THIRD-PARTY WEBSITES</h1>
                                <p className='page_para'>
                                Our Website, as well as the email messages sent with respect to our Services, may contain links or access to websites operated by third parties that are beyond our control. For example, if you click to request for a product such as a loan via loanfy.net , you may be linked to a third-party website to complete a request. Links or access to third parties from our Services are not an endorsement by us of such third parties, or their websites, requests, products, services, or practices. We are not responsible for the privacy policy, terms and conditions, practices or the content of such third parties.
                                </p>
                            </div>

                            <div className='page_inner_child'>
                                <h1 className='page_subheadding'>Information Sharing with Affiliates</h1>
                                <p className='page_para'>
                                When you put in a request for a quote or information you are in fact granting loanfy.net or our affiliates to contact you. We will share both the loan request data and your contact information which you submit for a quote in addition to your loanfy.net account number info with our affiliated companies like commercial lenders, licensed loan brokers and banks so that we can fulfill information requests that we get from you in order to ensure a superior service by providing service and product opportunities which may interest you and also various other business purposes we could disclose all information we have collected as described above with the following affiliated companies: mortgage and life insurance companies, numerous financial service providers, debt and credit services, title service companies and auto finance companies.
                                </p>
                            </div>

                            <div className='page_inner_child'>
                                <h1 className='page_subheadding'>Push Notifications</h1>
                                <p className='page_para'>
                                We may send push notifications or alerts to your device. We use push information to send you notifications related to offers. At any time, you can manage your push notification preferences or deactivate these notifications at any time by turning off the notifications in the browser settings of your device.
                                </p>
                            </div>

                        </div>



                    </div>
                </div>
                <div className='row'>
                    <ReadyBlock onSubmit={onSubmit} Click={eventTrack("Privacy", "Click")}/>
                </div>
                <Footer />
            </Container>
        </>
    )
}

export default Privacy