import React from 'react'
import { Container } from 'react-bootstrap'
import styles from "../ReadyBlock/ready.module.css"
const ReadyBlock = (props) => {
    const {onSubmit} = props;
    return (
        <>
            <Container fluid className="block_padding ">
                <div className={` ${styles.ready_main}`}>
                    <div className='container'>
                        <div className='row row_align'>

                            <div className='col-xl-7 col-lg-8 col-md-12 col-sm-12 col-12'>
                                <div className={`${styles.first_box_main}`}>
                                <h1 className="block_headding_white">Ready to take the next step toward financial success?</h1>
                                <p className="block_par_white">Click now to explore and see how we can help you achieve your dreams and build the future you deserve.</p>
                                </div>
                            </div>

                            <div className='col-xl-5 col-lg-4 col-md-12 col-sm-12 col-12'>
                                <div className={`request_btn_main ${styles.req_btn_main}`}>
                                    <button onClick={onSubmit} className='ready_request_btn'>Request Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default ReadyBlock