import React, { useEffect, useState } from 'react'
import { Container,  Nav, Navbar, Offcanvas } from 'react-bootstrap'
import styles from "../Header/header.module.css"
import { NavLink } from 'react-router-dom';
import { Squash } from 'hamburger-react';
import logoimg from "../../assets/LoanFylogo.png";
import axios from 'axios';
const Header = (props) => {
  
  const { onSubmit,eventTrack } = props;
  //creating function to load ip address from the API
  const [ip,setIP] = useState('');
    
    const getData = async()=>{
      const res = await axios.get('https://geolocation-db.com/json/')
      setIP(res.data.IPv4)
  }

  useEffect( () => {
    getData()
  }, [])
  
  // console.log("IP adderess",ip);

  const links = [
    {
      id: 1,
      text: "About Us",
      color: "green",
      url: "/about-us",
      activeStyle: { color: "#4270EC" },
    },
    {
      id: 2,
      text: "Contact",
      color: "red",
      url: "/contact-us",
    },

  ];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  function myFunction(){
    onSubmit();
    eventTrack('Home Page User ip', ip);
}

  return (
    <>

      {["lg"].map((expand) => (
        <>
          <Navbar key={expand} expand={expand} className={`${styles.nav_bg}`}>
            <Container fluid>
              <div className="container p-0">
                <div className={`${styles.nav_parent}`} >
                  <Navbar.Brand
                    href="/"
                    className={`${styles.logoCenterText2}`}
                  >
                    {/* <img
                      src={logoimg}
                      alt="headerlogo"
                      className={`${styles.header_logo}`}
                    /> */}
                    {/* Lend */}
                    <h1 className={`${styles.header_logo}`}>
                      <img src={logoimg} alt="logo"/>
                    </h1>

                  </Navbar.Brand>
                  <Navbar.Toggle
                    aria-controls={`offcanvasNavbar-expand-${expand}`}
                    className={`${styles.Hamburger_boder}`}
                  >

                    <div className={`${styles.Login_bar_main}`}>
                    <div className={`${styles.but_nav_start} ${styles.but_display}`}>
                   {
                     <button
                        className={`${styles.get_start_btn}`}
                        type="submit"
                        onClick={myFunction}
                      >
                        Log In
                        
                      </button>
                   }
                    </div>

                      
               

                      <div className={`${styles.hamburger_sty}`}>
                        <Squash direction="right" size={20} color="#1D1D1F" />
                      </div>

                    </div>
                  </Navbar.Toggle>


                  <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand-${expand}`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                    placement="end"
                    className="button button-effect offcanvas-top"
                  >
                    <Offcanvas.Body className={`${styles.contact_bg}`}>
                      <Nav
                        className={`justify-content-end flex-grow-1 ${styles.ulstylink}`}
                      >
                        {links.map((link, i) => (
                          <NavLink
                            className={`${styles.header_link}`}
                            // style={{ color: link.color }}
                            style={({ isActive }) => isActive ? link.activeStyle : { color: "#262250" }
                            }



                            key={link.id}
                            to={link.url}
                          > 
                            {link.text}
                          </NavLink>
                        ))}

                        <div className={`${styles.mob_btn_main}`}>

                          <button onClick={myFunction} className={`${styles.mob_btn_sty}`}>Request Loan
                            {/* <img src={Arrowblack} alt="arrow" className={` img-fluid ${styles.img_arrow}`} /> */}
                          </button>

                        </div>

                      </Nav>
                      <div
                        className={`${styles.but_nav_start} ${styles.but_display}`}

                      >
                        <button
                          onClick={myFunction}
                          className={`${styles.get_start_btn}`}
                          type="submit"
                        >
                          Request Now
                          {/* <img
                            src={login_img}
                            alt="login"
                            className={`img-fluid ${styles.login_img}`}
                          /> */}
                        </button>
                      </div>
                    </Offcanvas.Body>
                  </Navbar.Offcanvas>
                </div>
              </div>
            </Container>
          </Navbar>
        </>
      ))}


    </>
  )
}

export default Header