import React from 'react'
import { Container } from 'react-bootstrap'
import Header from '../../sections/Header/Header'
import aboutImg from "../../assets/aboutimg.png"
import dotsimg from "../../assets/dots.png"
import circle from "../../assets/circle.png"
import Footer from '../../sections/FooterBlock/Footer'
import ReadyBlock from '../../sections/ReadyBlock/ReadyBlock'
const AboutUs = (props) => {
    const {onSubmit,eventTrack} = props;
    return (
        <>
        
            <Container fluid>
                <div className='container'>
                    <Header />

                    <div className='page_main'>
                        <h1 className='page_headding'>About Us</h1>
                    </div>

                    <div className='inner_main'>
                        <div className='row '>
                            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                                <p className='page_f_para'>LoanFy is a lending network, not a direct lender. We work with reputable lenders and lending partners to provide a convenient way for you to find a loan that meets your needs. Our services are entirely free, and there are no hidden fees involved in filling out a loan request form. When you submit a loan request through our platform, you will be contacted by one of our lenders or lending partners to confirm your personal information and finalize the loan. Please note that LoanFy does not make credit decisions and is not responsible for the terms and conditions of any loan offered by our partners. Our goal is to make it easy for you to find the right loan option and to help you make informed decisions about borrowing.</p>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 position-relative'>
                                <div className='about_img_block'>
                                    <img src={aboutImg} className="img-fluid Aboutimg" alt='About' />
                                    <img src={dotsimg} className="img-fluid" alt='Aboutimg' id='dotImgtop' />
                                    <div id="box">
                                        <img src={circle} className="img-fluid" alt='Aboutimg' />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div className='row'>
                    <ReadyBlock onSubmit={onSubmit} Click={eventTrack("About-us", "Click")}/>
                </div>
                <Footer />
            </Container>
        </>
    )
}

export default AboutUs