import { ErrorMessage } from '@hookform/error-message';
import React, { useState } from 'react'
import { Container} from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import Api from '../../../API/Api';
import SuccesModal from '../../../Success_Modal/SuccesModal';
import Footer from '../../sections/FooterBlock/Footer';
import Header from '../../sections/Header/Header'
import ReadyBlock from '../../sections/ReadyBlock/ReadyBlock';

const ContactUs = (props) => {

  const { onSubmits ,eventTrack} = props;

  const [showSuccess, setShowSuccess] = useState(false);
  const handleCloseSuccess = () => setShowSuccess(false);

  const { register, handleSubmit,reset, formState: { errors }, } = useForm(); // initialize the hook
  const websitename ="Loanfy.io"
  const onSubmit = async (data) => {
    try {
      let formData = {
        ...data,websitename,
      };
      console.log("submit Contect Data", formData);
      await Api.post("?c=home&m=contactUs", formData);
   
      reset()
      setShowSuccess(!showSuccess)
   
    } catch (error) {
      console.error("error", error.message);
    }
  };

  return (
    <>
      <Container fluid>
        <div className='container'>
          <Header />

          <div className='page_main'>
            <h1 className='page_headding'>Contact Us</h1>
            <p className='page_para'>If you have a question, comment, or opinion about anything on this site, or if you’d like to submit a review or complaint about a lender, please use the form below. We appreciate any and all feedback, and we respect your privacy.</p>

            <div className='form_main'>
              <div className='row'>
                <div className='  col-lg-4 col-xl-4 col-md-12 col-sm-12 col-xs-12  p-0'>
                  <div className='contact_info_main'>
                    <div className='contact_block1'>
                      <h1>Our Address -</h1>
                      <p>13920 Landstar Blvd Suite#101 – 0361 Orlando FL , 32824</p>
                    </div>
                    <div className='contact_info_inner' >
                      <div className='contact_block1'>
                        <h1>Phone -</h1>
                        <p>+1-844-368-6075</p>
                      </div>
                      <div className='contact_block1'>
                        <h1>Email -</h1>
                        <p>info@loanfy.io</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-8 col-xl-8 col-md-12 col-sm-12 col-xs-12  p-0'>
                  <div className='form_parent'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="d-flex justify-content-between">
                        <div className="form-group  outer_input_margin" >
                          <label className="contact_lable">First Name</label>
                          <input
                            className="form-control contact_input"
                            id="firstname"
                            placeholder="First Name"
                            name="firstname"
                            {...register("firstname", {
                              required: "This input is required.",

                              minLength: {
                                value: 3,
                                message:
                                  "minmum length ",
                              },
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="firstname"
                            render={({ message }) => (
                              <p className="error_color">{message}</p>
                            )}
                          />
                        </div>
                        <div className="form-group outer_input_margin">
                          <label className="contact_lable">Last Name</label>
                          <input
                            type="text"
                            className="form-control contact_input"
                            id="lastname"
                            placeholder="Last Name"
                            name="lastname"
                            {...register("lastname", {
                              required: "This input is required.",
                            })}
                          />

                          <ErrorMessage
                            errors={errors}
                            name="lastname"
                            render={({ message }) => (
                              <p className="error_color">{message}</p>
                            )}
                          />
                        </div>
                      </div>

                      <div className="form-group outer_input_margin">
                        <label className="contact_lable">Email Address</label>
                        <input
                          type="email"
                          className="form-control contact_input"
                          id="email"
                          name="email"
                          placeholder="Email Address"
                          {...register("email", {
                            required: "Email Address is required",

                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "invalid email address",
                            },
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="email"
                          render={({ message }) => (
                            <p className="error_color">{message}</p>
                          )}
                        />
                      </div>

                      <div className="form-group outer_input_margin">
                        <label className="contact_lable">Message</label>
                        <textarea
                          className="form-control contact_input"
                          type="text"
                          name="message"
                          id="message"
                          rows="4"
                          placeholder="Message"
                          {...register("message", {
                            required: "This input is required.",
                            minLength: {
                              value: 10,
                              message:
                                "Minimum 10 characters",
                            },

                          })}
                        ></textarea>
                        <ErrorMessage
                          errors={errors}
                          name="message"
                          render={({ message }) => (
                            <p className="error_color">{message}</p>
                          )}
                        />
                      </div>
                      <div className="contact_btn_main">
                        <button type="submit" className="contact_btn_sub">
                          Submit
                        </button>
                      </div>
                    </form>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <ReadyBlock onSubmit={onSubmits} Click={eventTrack("Contact-Us", "Click")}/>
        </div>
        <Footer />
      </Container>
      <SuccesModal show={showSuccess} handleClose={handleCloseSuccess}/>
    </>
  )
}

export default ContactUs