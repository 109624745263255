import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import styles from "../FirstBlock/firstblock.module.css"
import FbannerImg from "../../assets/Fbanner.png"
import useQuery from './useQuery'
import { createSearchParams, Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

const FirstBlock = () => {

    const {  handleSubmit, reset } = useForm();
    const [formData, setformData] = useState({});
    console.log("params", formData);
// only form page
    const query = useQuery();
    // const queryName = query.getAll;
    const uid = query.get("utm_source") || "";
    const utm_campaign = query.get("utm_campaign") || "";
    const utm_medium = query.get("utm_medium") || "";
    const s2 = query.get("s2") || "";
    const s3 = query.get("s3") || "";
    const s4 = query.get("s4") || "";

    const navigate = useNavigate();


    const onSubmit = (data) => {
        console.log(data);
    
        const myData = {
          ...data,
          uid,
          utm_campaign,
          utm_medium,
          s2,
          s3,
          s4,
        };
        setformData(myData);
        console.log(myData);
    
        const params = {
          s1: myData.utm_campaign,
          s2: myData.s2,
          s3: myData.s3,
          s4: myData.s4,
          uid: myData.uid,
          utm_medium: myData.utm_medium,
          amount: myData.amount || "",
    
        };
        console.log("params", params);
    
        navigate({
          pathname: "/form",
          search: `?${createSearchParams(params)}`,
        });
    
        reset();
      };


    return (
        <Container fluid className='f_main'>
            <div className='container f_block_padding'>
                <div className='row  row_align'>
                    <div className='col-sm-12 col-xs-12 col-md-12 col-lg-7 col-xl-7  order-lg-last p-0'>
                        <div>
                            <img src={FbannerImg} className={`img-fluid ${styles.banner_img}`} alt="banner" />
                        </div>
                    </div>

                    <div className='col-sm-12 col-xs-12 col-md-12 col-lg-5 col-xl-5 '>
                        <div className={` ${styles.banner_lr_padding}`}>
                            <h1 className={` ${styles.banner_headding}`}>
                                Request Personal Loan In A Few Clicks
                            </h1>
                            <p className={`${styles.banner_para}`}>
                                LoanFy finds you loan offers as per your financial needs to make your life easy and stress-free.
                            </p>
                            <div className='request_btn_main'>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <button type='submit' className='request_btn'>Request Now</button>
                                </form>
                            </div>
                            <div className='banner_cta_main'>
                                <p className={`${styles.banner_cta_para}`}>By clicking 'Request Now' ,you agree to our <Link to="/privacy-policy"><span >Privacy Policy</span></Link> , <Link to="/terms-of-services"><span>Terms</span></Link> , <Link to="/e-consent"><span>E-Consent</span></Link>, <Link to="/rate-fees"><span>Rate & Fees</span></Link> and receive special offers from us and our marketing partners via email communication.</p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </Container>
    )
}

export default FirstBlock