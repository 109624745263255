import React from 'react'
import { Accordion, Container } from 'react-bootstrap'
import styles from "../FaqBlock/faq.module.css"
const Faq = () => {
    return (
        <>
            <Container fluid className='block_padding'>
                <div className='container'>

                    <div  className={`row row_align ${styles.waterimg1}`}>

                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12' >
                            <div className={`${styles.first_box_main}`}>
                                <div>
                                    <h1 className='block_headding_black'>Frequently Asked Questions ?</h1>
                                </div>
                                <h1 className="block_par">We've got you covered. We're like a friend with an answer for everything.</h1>
                            </div>
                        </div>


                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>


                            <div >


                                {/* <p>{i}</p> */}

                                <div className='faq_main'>


                                    <Accordion flush>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header >
                                                <p className={`${styles.faqs_quse_text}`}> What amount could I get?</p>

                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>You could be eligible for any amount up to $10000. The approved amount will be determined by the lender. Having a working email address, work telephone number, and home telephone are some of the usual prerequisites for getting a loan.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header >
                                                <p className={`${styles.faqs_quse_text}`}> How much time does it take? </p>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>It could take a few minutes to process the duly filled form. You could get your loans after approval within the next business day.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header >
                                                <p className={`${styles.faqs_quse_text}`}>Does it cost anything to use LoanFy? </p>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>There is no cost to use LoanFy. The loan fees will vary depending on the amount and the lender. The lender will inform you of the fees for the loan, which may differ depending on the lender.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item id="3" eventKey="3">
                                            <Accordion.Header >
                                                <p className={`${styles.faqs_quse_text}`}>What are the requirements to submit a loan?</p>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>- Should be employed in your current position for at least 90 days
                                                    - Should be older than 18 years of age, should be a US citizen or a permanent resident
                                                    - Should have a minimum income of $1000 each month after tax deductions
                                                    - Should have a valid email address and work and home phone number.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item id="4" eventKey="4">
                                            <Accordion.Header >
                                                <p className={`${styles.faqs_quse_text}`}>How is my private data to be secured?</p>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>We use state-of-the-art technology and security systems. All your private information is kept securely in an encrypted format to ensure your security and privacy.
                                                </p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item id="5" eventKey="5">
                                            <Accordion.Header >
                                                <p className={`${styles.faqs_quse_text}`}>If I have an outstanding loan, can I get another one?</p>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>Yes, regardless of the possibility that you have another outstanding loan, you can still qualify. In any case, you should make sure to disclose this outstanding loan to the lender.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    </Accordion>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Faq