import React from 'react'
import { Container } from 'react-bootstrap'
import Footer from '../../sections/FooterBlock/Footer'
import Header from '../../sections/Header/Header'
import ReadyBlock from '../../sections/ReadyBlock/ReadyBlock'

const Disclaimer = (props) => {
    const { onSubmit ,eventTrack} = props;
    return (
        <>
            <Container fluid>
                <div className='container'>
                    <Header />

                    <div className='page_main'>
                        <h1 className='page_headding'>Disclaimer</h1>


                        <div className='page_inner_main'>
                            <div className=''>
                              
                                <p className='page_para'>
                                loanfy.io is not a moneylender and does not make advances or credit choices. loanfy.io merely gives a connecting service and is not a delegate, operator, or correspondent for any specialist organization or lender. You are under no compulsion to use loanfy.io's service to contact with, fill credit from, or accept any loan offer from any service provider or lender. This service is not available in all states.
                                </p>
                                <p className='page_para'>loanfy.io doesn't charge any fee for submitting a loan request. loanfy.io is not a loan giver and does not lend money. In light of the data you submit, loanfy.io will look through its huge network of lenders keeping in mind the end goal to connect you with a loan provider that might have the capacity to serve your requirements.</p>
                                <p className='page_para'>In the event that your loan request is acknowledged by a loan provider, you will be diverted to an e-signature page on the moneylender's site. All expenses and rate data charged by the moneylender ought to be disclosed to you around then. You should thoroughly understand the charges and rate information given by the money lender before consenting to the terms of the credit or signing any official records. You are under no commitment to acknowledge the terms.</p>
                                <p className='page_para'>In the event that you decide that the advance terms given by the lender are satisfactory and wish to continue, you may e-signature the record proving your concurrence with those terms. After signing, you will go into a lawfully binding contract with the loan provider and will be in charge of all risk related to the agreement. Your legally binding commitments are to the loan providers, and not to loanfy.io. The loan cost and loan fees you consent to are set exclusively by the moneylender and not loanfy.io.</p>
                                <p className='page_para'>loanfy.io does not ensure that your advance request will be accepted by a lender or other specialist organization. In case that you are offered a loan, loanfy.io does not ensure that the charges and rates will be adequate to you or that your advance will be subsidized. All choices relating to your loan are the moneylenders' choices, not loanfy.io's.</p>
                                <p className='page_para'>Loan terms may vary depending on the lender that you are connected with. This website does not constitute an offer or solicitation to lend. We at loanfy.io try our best to connect you with the most suitable lender, however approval for funding/loan is not guaranteed and is fully determined by the lender. The form on the website will not be considered as an application for a loan and loanfy.io will share PII with third-parties for the purpose of connecting consumers with lenders. Third-party provider may conduct credit checks related to the user's request. loanfy.io will not charge any fee or remuneration from the user of this website, however the operator of this website is compensated by lenders for connecting consumers with lenders.</p>

                            </div>

                           




                        </div>



                    </div>
                </div>
                <div className='row'>
                    <ReadyBlock onSubmit={onSubmit} Click={eventTrack("Disclaimer", "Click")}/>
                </div>
                <Footer />
            </Container>
        </>
    )
}

export default Disclaimer