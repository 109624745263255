import React, { useEffect, useRef } from 'react'
import { Container } from 'react-bootstrap'
import { createSearchParams, useNavigate } from 'react-router-dom'
import Faq from '../../sections/FaqBlock/Faq'
import FirstBlock from '../../sections/FirstBlock/FirstBlock'
import useQuery from '../../sections/FirstBlock/useQuery'
import Footer from '../../sections/FooterBlock/Footer'
import Header from '../../sections/Header/Header'
import ReadyBlock from '../../sections/ReadyBlock/ReadyBlock'
import SliderBlock from '../../sections/SliderBlock/SliderBlock'
import StepsBlock from '../../sections/StepsBlock/StepsBlock'
import WhyUs from '../../sections/WhyUsBlock/WhyUs'


const Home = (props) => {
  const ref = useRef(null);

  const { eventTrack }  =props;


// All section component request btn use submit function
  
  // Get query Params
  const query = useQuery();
  // const queryName = query.getAll;
  const uid = query.get("utm_source") || "";
  const utm_campaign = query.get("utm_campaign") || "";
  const utm_medium = query.get("utm_medium") || "";
  const s2 = query.get("s2") || "";
  const s3 = query.get("s3") || "";
  const s4 = query.get("s4") || "";

  const params = { s1: utm_campaign, s2: s2, s3: s3, s4: s4, uid: uid, utm_medium: utm_medium };
  console.log("params", params);

  const navigate = useNavigate();
  // navigate('/form');

  const onSubmit = (data) => {
    navigate({
      pathname: "/form",
      search: `?${createSearchParams(params)}`,
    });
  };

 
 

  return (
    <>
   
      <Container fluid>
      
        <div className='row'>
          <Header onSubmit={onSubmit} eventTrack={eventTrack} />
          <div className='firstblock_top' ref={ref} >
            <div className='custom_paddng_first'  >
              <FirstBlock />
            </div>

            <StepsBlock  onSubmit={onSubmit}/>
            <div className='custom_paddng_why'>
              <WhyUs onSubmit={onSubmit}/>
            </div>
            <SliderBlock onSubmit={onSubmit}/>
            <Faq />
            <div className='custom_paddng_ready'>
              <ReadyBlock onSubmit={onSubmit}/>
            </div>
            <Footer onSubmit={onSubmit} />

          </div>
        </div>
      </Container>
    </>
  )
}

export default Home