import React from 'react'
import { Container } from 'react-bootstrap'
import styles from "../StepsBlock/steps.module.css"

import stepimg1 from "../../assets/stepimg1.svg"
import stepimg2 from "../../assets/stepimg2.svg"
import stepimg3 from "../../assets/stepimg3.svg"


const StepsBlock = (props) => {
    const {onSubmit} = props;


    return (
        <>
            <Container fluid className={`block_padding ${styles.waterimg}`} >
                <div className='container'>
                    <div className='row row_align'>

                    <div className='col-xl-6 col-lg-5 col-md-12 col-sm-12 order-lg-last   col-12 p-0'  >
                            <div className={`${styles.second_box_main}`}>
                                <h1 className="block_headding_black">3 Simple Steps To Request For Loan</h1>
                                <h1 className="block_par">LoanFy makes the loan request process so quick that you can get a loan in a really short time. Just Submit the Form, We will connect you with the right lender. And Done! Your loan request is completed.</h1>
                                <div className='request_btn_main' >
                                    <button onClick={onSubmit} className='request_btn'>Request Now</button>
                                </div>
                            </div>
                        </div>

                        <div className='col-xl-6 col-lg-7 col-md-12 col-sm-12 col-12 p-0'>
                            <div className='row row_align'>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-0'>
                                    <div className={`${styles.box_parent} `}>
                                        <div className={`${styles.box_inner}`}>
                                            <img src={stepimg1} alt="stepimg" />
                                            <h1 className="block_headding">Submit your information</h1>
                                            <p className="block_par">Fill out a short, simple, and secure online loan request form that only takes a few minutes.</p>
                                        </div>
                                        <div className={`${styles.box_inner}`}>
                                            <img src={stepimg2} alt="stepimg" />
                                            <h1 className="block_headding">Check your account</h1>
                                            <p className="block_par">Once Approved, Funds will be transferred directly to your bank account in no time.</p>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 p-0'>
                                    <div className={`${styles.box_inner} ${styles.mob_sty}`}>
                                        <img src={stepimg3} alt="stepimg" />
                                        <h1 className="block_headding">Fast Process</h1>
                                        <p className="block_par">We navigate your profile to the best-suited lender. As soon as It is approved, the application process is done.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                      

                    </div>

                </div>
            </Container>
        </>
    )
}

export default StepsBlock