import React, { useState } from 'react'
import { Accordion, Carousel, Container } from 'react-bootstrap'
import slide1 from "../../assets/slide1.png"
import slide2 from "../../assets/slide5.png"
import slide3 from "../../assets/slide4.png"
import slide4 from "../../assets/slide3.png"
import slide5 from "../../assets/slide2.png"
import styles from "../SliderBlock/slider.module.css"

const SliderBlock = (props) => {
    
    const {onSubmit} = props;

    const [id, setId] = useState(0);
    console.log("accordion id", id);


    // const [index, setIndex] = useState(0);
    // const handleSelect = (selectedIndex, e) => {
    //     setIndex(selectedIndex);
    //     console.log("Index No", selectedIndex);

    // };


   const handleId = (e) => {
      
         console.log(e.target.id);
         setId(e.currentTarget.id)
        //  console.log(e.currentTarget.id);
       }
    // console.log("Index No", index);




    return (
        <>
            <Container fluid className="block_padding">
                <div className='container'>
                    <div className='row row_align'>
                    <div className={`${styles.slider_headdings2}`}>
                                    <h1 className='block_headding_black'>Personal Loan that fits all your needs.</h1>
                                </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                            <>
                                <Carousel activeIndex={Number(id)} interval={null}>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={slide1}
                                            alt="First slide"
                                        />
                                        {/* <Carousel.Caption>
                                            <h3>First slide label</h3>
                                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={slide2}
                                            alt="Second slide"
                                        />

                                        {/* <Carousel.Caption>
                                            <h3>Second slide label</h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={slide3}
                                            alt="Third slide"
                                        />

                                        {/* <Carousel.Caption>
                                            <h3>Third slide label</h3>
                                            <p>
                                                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                                            </p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={slide4}
                                            alt="Third slide"
                                        />

                                        {/* <Carousel.Caption>
                                            <h3>fourth slide label</h3>
                                            <p>
                                                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                                            </p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={slide5}
                                            alt="Third slide"
                                        />

                                        {/* <Carousel.Caption>
                                            <h3>fifth slide label</h3>
                                            <p>
                                                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                                            </p>
                                        </Carousel.Caption> */}
                                    </Carousel.Item>
                                </Carousel>
                            </>



                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12' >
                            <div className={`${styles.second_box_main}`}>
                                <div className={`${styles.slider_headdings1}`}>
                                    <h1 className='block_headding_black'>Personal Loan that fits all your needs.</h1>
                                </div>
                               
                                {/* <p>{i}</p> */}

                                <div className='slider_faq_main'>
                               

                                    <Accordion flush htmlFor="faq" defaultActiveKey="0">
                                        <Accordion.Item id="0" onClick={handleId}  eventKey="0">
                                            <Accordion.Header >
                                                <p  className={`${styles.faqs_quse_text}`}> Debt Consolidation - </p>

                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>Consolidate your debt and simplify your finances with a personal loan.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item id="1" onClick={handleId} eventKey="1">
                                            <Accordion.Header >
                                                <p className={`${styles.faqs_quse_text}`}> Home Improvement - </p>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>Don't let financial constraints hold you back from improving your home - take out a personal loan. </p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item  id="2" onClick={handleId} eventKey="2">
                                            <Accordion.Header >
                                                <p className={`${styles.faqs_quse_text}`}>Small Business- </p>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>Grow your small business and your bank account with a personal loan. Get the financing you need to take your business to the next level.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item  id="3" onClick={handleId} eventKey="3">
                                            <Accordion.Header >
                                                <p className={`${styles.faqs_quse_text}`}>Wedding Expenses -</p>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>Make your dream wedding a reality with a personal loan. Say 'I do' to finance your special day.</p>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item  id="4" onClick={handleId} eventKey="4">
                                            <Accordion.Header >
                                                <p className={`${styles.faqs_quse_text}`}>Medical Bills -</p>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>Don't let medical bills hold you back, get a personal loan and take charge of your health today!</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <div className='request_btn_main'>
                                            <button onClick={onSubmit} className='request_btn'>Request Now</button>
                                        </div>
                                    </Accordion>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
        </>
    )
}

export default SliderBlock