import { ErrorMessage } from '@hookform/error-message';
import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import Api from '../../../API/Api';
import SuccesModal from '../../../Success_Modal/SuccesModal';
import Footer from '../../sections/FooterBlock/Footer';
import Header from '../../sections/Header/Header'
import ReadyBlock from '../../sections/ReadyBlock/ReadyBlock';

const Unsub = (props) => {
  const { onSubmits ,eventTrack} = props;
  const [showSuccess, setShowSuccess] = useState(false);
  const handleCloseSuccess = () => setShowSuccess(false);

  const { register, handleSubmit, reset, formState: { errors }, } = useForm(); // initialize the hook

  const websitename = "Loanfy.io"
  const onSubmit = async (data) => {
    try {
      let formData = {
        ...data, websitename,

      };
      console.log("submit Unssssssssss", formData);
      await Api.post("?c=home&m=unsub", formData);

      reset()
      setShowSuccess(!showSuccess)
    }
    catch (error) {
      console.error("error", error.message);
    }
  };

  return (
    <>
      <Container fluid>
        <div className='container'>
          <Header />

          <div className='page_main'>
            <h1 className='page_headding'>Unsubscribe</h1>

            <div className='page_inner_main'>
              <h1 className='page_subheadding'>You Will Be Missed</h1>
              <p className='page_para'>We hate goodbyes but if you have a change of heart, we will always be here to search the loan option for you.</p>



              <div className='form_main'>
                <div className='row'>


                  <div className='form_parent_unsub'>
                    <form onSubmit={handleSubmit(onSubmit)}>

                      <div className="form-group outer_input_margin">
                        <label className="contact_lable">Email Address</label>
                        <input
                          type="email"
                          className="form-control contact_input"
                          id="unsubmail"
                          name="unsubmail"
                          placeholder="Email Address"
                          {...register("unsubmail", {
                            required: "Email Address is required",

                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "invalid email address",
                            },
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="unsubmail"
                          render={({ message }) => (
                            <p className="error_color">{message}</p>
                          )}
                        />
                      </div>


                      <div className="contact_btn_main">
                        <button type="submit" className="btn_unsub">
                          Unsubscribe
                        </button>
                      </div>
                    </form>

                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <ReadyBlock onSubmit={onSubmits} Click={eventTrack("Unsub", "Click")}/>
        </div>
        <Footer />
      </Container>
      <SuccesModal show={showSuccess} handleClose={handleCloseSuccess} />
    </>
  )
}

export default Unsub