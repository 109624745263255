import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from "../FooterBlock/footer.module.css";
import logoimg from "../../assets/LoanFylogo.png";
const olaimg = "https://stage.ola-memberseal.org/seal?seal=member&token=ZxDT1w0xTuqf8298Sr67"
const Footer = () => {

    return (
        <>
            <Container fluid className='block_padding'>
                <div className='container'>
                    <div className='row'>

                        <div className='col-xl-6 col-lg-6 col-md-4 col-sm-12 col-12'>
                            <div className={`${styles.first_box_main}`}>
                                <h1 className={`${styles.footer_logo}`}>
                                    <img src={logoimg} alt="ola"/>
                                </h1>
                                <p className={`${styles.logo_para}`}>We offering simple, quick and secure access to lenders.</p>

                                <a href="https://onlinelendersalliance.org/look-for-the-ola-seal" rel="noreferrer" target="_blank">

                                    <img
                                        src={olaimg}
                                        alt="footer_logo"
                                        style={{ paddingTop: "20px", width: "50px", height: "100%" }}
                                    />
                                </a>
                            </div>

                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6'>
                            <div className={`${styles.link_main}`}>
                                <li><Link to="/about-us">About Us</Link></li>
                                <li><Link to="/terms-of-Services">Terms Of Services</Link> </li>
                                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                <li><Link to="/do-not-sell-my-personal-information">Do not sell my information</Link></li>
                            </div>
                        </div>
                        <div className='col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6'>
                            <div className={`${styles.link_main}`}>
                                <li> <Link to="/e-consent">E-Consent</Link></li>
                                <li><Link to="/disclaimer">Disclaimer</Link></li>
                                <li ><Link to="/faqs">FAQs</Link></li>
                                <li><Link to="/unsubscribe">Unsubscribe</Link></li>
                            </div>

                        </div>
                        <div className={`${styles.footer_para_main}`}>
                            <p className='block_par'>
                                THE OPERATOR OF THIS WEBSITE IS NOT A LENDER, is not a loan broker, and does not make lending decisions on behalf of lenders. This Web Site does not constitute an offer or solicitation to lend. This site will submit the information you provide to a lender who makes short-term cash loans to borrowers who meet its lending criteria. Providing your information on this Website does not guarantee that you will be approved for a short term cash loan. The operator of this Web Site is not an agent, representative or broker of any lender and does not endorse any particular lender or charge you for any service or product. Not all lenders can provide the maximum amount advertised. Cash transfer times may vary between lenders and may depend on your individual financial institution. In some circumstances faxing may be required. This service is not available in all states, and the states serviced by this Web Site may change from time to time and without notice. For details, questions or concerns regarding your short-term cash loan, please contact your lender directly. Short term cash loans are meant to address immediate cash needs and are not a long-term solution for financial problems. Residents of some states may not be eligible for a short term cash loan based upon lender requirements.
                            </p>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Footer