import React from 'react'
import { Container } from 'react-bootstrap'
import styles from "../WhyUsBlock/whyus.module.css"
import whyus1 from "../../assets/whyimg1.svg"
import whyus2 from "../../assets/whyimg2.svg"
import whyus3 from "../../assets/whyimg3.svg"
import whyus4 from "../../assets/whyimg4.svg"
const WhyUs = (props) => {
    const {onSubmit} = props;
    return (
        <Container fluid className="block_padding why_custom_mob_main">
            <div className='container why_custom_mob'>
                <div className={`${styles.why_us_main}`}>
                    <div className='row '>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                          <div className={`${styles.why_us_block1}`}>
                          <h1 className='block_headding_white'>Why Us ?</h1>
                            <p className={`${styles.headding_sub_tex}`}>Fast.Simple.Secure</p>
                            <p className='block_par_white'>Life is much more than paying bills and focusing on finances. That’s Why LoanFy is Here, to help you find the right loan from the right lender fast and make the process as simple as one, two, three.</p>
                            <div className='request_btn_main'>
                                <button onClick={onSubmit} className='request_btn'>Request Now</button>
                            </div>
                          </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                            <div className='row'>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6'>
                                    <div className={`${styles.whyus_box_main}`}>
                                        <img src={whyus1} alt="whyus" />
                                        <h1 className={`${styles.whyus_box_hedding}`}>All Credit Score Accepted</h1>
                                        <p className={`${styles.whyus_box_para}`}>All credit scores are welcome to submit a loan request.</p>
                                    </div>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6'>
                                    <div className={`${styles.whyus_box_main}`}>
                                        <img src={whyus2} alt="whyus" />
                                        <h1 className={`${styles.whyus_box_hedding}`}>Minimal Documentation</h1>
                                        <p className={`${styles.whyus_box_para}`}>Minimum Documents needed to request for a loan</p>
                                    </div>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6'>
                                    <div className={`${styles.whyus_box_main} ${styles.box_padding} `}>
                                        <img src={whyus3} alt="whyus" />
                                        <h1 className={`${styles.whyus_box_hedding}`}>Simple & Secure Process</h1>
                                        <p className={`${styles.whyus_box_para}`}>Our advanced technology makes the process simple and reliable.</p>
                                    </div>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 col-6'>
                                    <div className={`${styles.whyus_box_main} ${styles.box_padding} `}>
                                        <img src={whyus4} alt="whyus" />
                                        <h1 className={`${styles.whyus_box_hedding}`}>Quick Results</h1>
                                        <p className={`${styles.whyus_box_para}`}>We have designed the process to be as fast and user friendly as possible.</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Container>
    )
}

export default WhyUs