import React from 'react'
import { Accordion, Container } from 'react-bootstrap'
import Footer from '../../sections/FooterBlock/Footer'
import Header from '../../sections/Header/Header'
import ReadyBlock from '../../sections/ReadyBlock/ReadyBlock'

const Faqs = (props) => {
    const { onSubmit ,eventTrack} = props;
    return (
        <>

            <Container fluid>
                <div className='container'>
                    <Header />
                    <div className='page_main'>
                        <h1 className='page_headding'>Frequently Asked Questions</h1>
                        <p className='page_para'>We've got you covered. We're like a friend with an answer for everything.</p>

                        <div className='inner_main'>
                            <Accordion flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header >
                                        <p className='faq_que'>What amount could I get? </p>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p>You could be eligible for any amount up to $10000. The approved amount will be determined by the lender. Having a working email address, work telephone number, and home telephone are some of the usual prerequisites for getting a loan.

                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">

                                    <Accordion.Header >
                                        <p className='faq_que'>What are the requirements to submit a loan?</p>

                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            <ul>
                                                <li>Should be employed in your current position for at least 90 days.</li>
                                                <li>Should be older than 18 years of age, should be a US citizen or a permanent resident.</li>
                                                <li>Should have a minimum income of $1000 each month after tax deductions.</li>
                                                <li> Should have a valid email address and work and home phone number.</li>
                                            </ul>
                                        </p>
                                    </Accordion.Body>



                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header >
                                        <p className='faq_que'>How much time does it take?</p>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p>It could take a few minutes to process the duly filled form. You could get your loans after approval within the next business day.</p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item id="3" eventKey="3">
                                    <Accordion.Header >
                                        <p className='faq_que'>Does it cost anything to use LoanFy?</p>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p>There is no cost to use LoanFy. The loan fees will vary depending on the amount and the lender. The lender will inform you of the fees for the loan, which may differ depending on the lender.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item id="4" eventKey="4">
                                    <Accordion.Header >
                                        <p className='faq_que'>How is my private data to be secured?</p>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p>We use state-of-the-art technology and security systems. All your private information is kept securely in an encrypted format to ensure your security and privacy.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item id="5" eventKey="5">
                                    <Accordion.Header >
                                        <p className='faq_que'>Will I require a printer or fax machine?</p>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p>You should have a printer to print out the loan documents. Also, the lender who receives your request will require you to fax over the data to him. Data such as your bank statement and compensation stub should be faxed over.</p>
                                    </Accordion.Body>
                                </Accordion.Item>


                                <Accordion.Item id="6" eventKey="6">
                                    <Accordion.Header >
                                        <p className='faq_que'>On the off chance that I have bankruptcy or awful credit, would I be able to get the loan still?</p>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p>Yes, you could still get it despite bounced checks, bankruptcy, and credit-related issues.</p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item id="7" eventKey="7">
                                    <Accordion.Header >
                                        <p className='faq_que'>When and how would I reimburse this loan?</p>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p>Both the loan fees and the sum is debited from your bank account once the due date comes. This saves you from visiting any store. An extension can be requested by reaching your lender before the due date. Nevertheless, there will be extra fees that apply to the extension.</p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item id="5" eventKey="8">
                                    <Accordion.Header >
                                        <p className='faq_que'>What happens If I'm late with reimbursement?</p>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p>There are specific policies set by various lenders in our network. It is advised that you check the policies relating to late installments frequently found in the lender's documentation before inserting your e-signature on that archive. Many states have laws that direct how much lenders are permitted to charge for late installments.</p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item id="5" eventKey="9">
                                    <Accordion.Header >
                                        <p className='faq_que'>Is an extension possible on reimbursing my loan? What's more, what is the number of extensions I would be able to get?</p>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p>The state laws on this vary if a lender can allow you an extension, frequently alluded to as a 'rollover.' While some states take into account different rollovers, others don't permit it whatsoever. You should ask your lender about anything concerning rollovers/extensions. Also, reach your lender with an extension request before the due date of your installment. If you don't do it in </p>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item id="5" eventKey="9">
                                    <Accordion.Header >
                                        <p className='faq_que'>What happens in case I'm not ready to reimburse my loan?</p>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <p>Lenders are free to pursue collections after the due date of their accounts to the fullest lawful extent. You may have to face lawsuits/charges and extra fees if you don't reimburse in time.</p>
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>
                        </div>

                    </div>

                </div>
                <div className='row'>
                    <ReadyBlock onSubmit={onSubmit} Click={eventTrack("Faqs", "Click")}/>
                    <Footer />
                </div>

            </Container>
        </>
    )
}

export default Faqs