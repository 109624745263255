import {  BrowserRouter as Router,createSearchParams,Navigate,Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css"
import Home from './components/pages/Home/Home';
import Faqs from './components/pages/Faqs/Faqs';
import AboutUs from './components/pages/AboutUs/AboutUs';
import ContactUs from './components/pages/ContactUs/ContactUs';
import Unsub from './components/pages/Unsub/Unsub';
import Terms from './components/pages/TermsServices/Terms';
import Privacy from './components/pages/Privacy/Privacy';
import Econsent from './components/pages/Econsent/Econsent';
import Disclaimer from './components/pages/Disclaimer/Disclaimer';
import Info from './components/pages/Info/Info';
import NotFound from './components/pages/NotFound/NotFound';
import Form from './components/Form/Form';
import RateFees from './components/pages/RateFees/RateFees';
import ReactGA from 'react-ga4';
import useQuery from './components/sections/FirstBlock/useQuery';
import useScript from './components/utils/ScriptLoder';

function App() {

// Ga Tracker===
    const location = useLocation(); // ReactGA.initialize("G-F0KGZ5PG0B");
    ReactGA.initialize("UA-125397312-34");


// ReactGA.send({ hitType: "pageview", page: "/home", title: "Custom Title" });
  ReactGA.send({hitType: "pageview", page: location.pathname});

  //===== Ga Event======
  const eventTrack = (category, action, label) => {
    console.log("GA event:", category, ":", action, ":", label);
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    })
}

// Redirection to Form
// All section component request btn use submit function
  
  // Get query Params
  const query = useQuery();
  // const queryName = query.getAll;
  const uid = query.get("utm_source") || "";
  const utm_campaign = query.get("utm_campaign") || "";
  const utm_medium = query.get("utm_medium") || "";
  const s2 = query.get("s2") || "";
  const s3 = query.get("s3") || "";
  const s4 = query.get("s4") || "";

  const params = { s1: utm_campaign, s2: s2, s3: s3, s4: s4, uid: uid, utm_medium: utm_medium };
  console.log("params", params);

  const navigate = useNavigate();

const onSubmit = (data) => {
  navigate({
    pathname: "/form",
    search: `?${createSearchParams(params)}`,
  });
};

// Pushnami ADD

useScript("https://api.pushnami.com/scripts/v1/push/63f8abae68152e0013080b7d")


  return (
    <>
      
      
        <Routes>
       
          <Route  exact path="/" element={<Home onSubmits={onSubmit} eventTrack={eventTrack}/>} />
          <Route exact path="/faqs" element={<Faqs onSubmit={onSubmit} eventTrack={eventTrack} />} />
          <Route exact path="/about-us" element={<AboutUs onSubmit={onSubmit} eventTrack={eventTrack} />} />
          <Route exact path="/contact-us" element={<ContactUs  onSubmits={onSubmit} eventTrack={eventTrack} />} />
          <Route exact path="/unsubscribe" element={<Unsub onSubmits={onSubmit} eventTrack={eventTrack} />}  />
          <Route exact path="/terms-of-services" element={<Terms onSubmit={onSubmit} eventTrack={eventTrack}/>} />
          <Route exact path="/privacy-policy" element={<Privacy onSubmit={onSubmit} eventTrack={eventTrack}/>} />
          <Route exact path="/e-consent" element={<Econsent onSubmit={onSubmit} eventTrack={eventTrack} />   }/>
          <Route exact path="/disclaimer" element={<Disclaimer onSubmit={onSubmit} eventTrack={eventTrack}/>} />
          <Route exact path="/do-not-sell-my-personal-information" element={<Info onSubmit={onSubmit} eventTrack={eventTrack}/>} />
          <Route exact path="/rate-fees" element={<RateFees onSubmit={onSubmit} eventTrack={eventTrack}/>} />
          <Route path='*' element={<NotFound />} />
          <Route exact path="/form" element={<Form />} />
          
          
        </Routes>
      


    </>
  );
}

export default App;
