import React, { useEffect, useRef, useState } from "react";
import favimg from "../assets/fav.svg";
import useQuery from "../sections/FirstBlock/useQuery";
import Header from "../sections/Header/Header";
const Form = () => {
  // Start Resizer
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  // useRef allows us to "store" the div in a constant,
  // and to access it via observedDiv.current

  const observedDiv = useRef(null);

  // we also instantiate the resizeObserver and we pass
  // the event handler to the constructor
  const handleElementResized = () => {
    if (observedDiv.current.offsetWidth !== width) {
      setWidth(observedDiv.current.offsetWidth);
    }
    if (observedDiv.current.offsetHeight !== height) {
      setHeight(observedDiv.current.offsetHeight);
    }
  };

  const resizeObserver = new ResizeObserver(handleElementResized);

  useEffect(() => {
    // the code in useEffect will be executed when the component
    // has mounted, so we are certain observedDiv.current will contain
    // the div we want to observe
    resizeObserver.observe(observedDiv.current);

    // if useEffect returns a function, it is called right before the
    // component unmounts, so it is the right place to stop observing
    // the div
    return function cleanup() {
      resizeObserver.disconnect();
    };
  });
  // End Resizer

  // Query Parms Get
  const query = useQuery();
  //   const queryName = query.get();
  //   console.log("QueryParas",queryName);

  const s1 = query.get("s1");
  const uid = query.get("uid");
  // const utm_medium = query.get("utm_medium");
  const s2 = query.get("s2");
  const s3 = query.get("s3");
  const s4 = query.get("s4");
  // const amount = query.get("amount");
  
  // const url = `https://useasycash.com/formcdn/v-1.2.1/?c=falcon&domain=loanfy.io&sub_id=LENFY&applied_amount=${amount}&email=&s1=${s1}&s2=${s2}&s3=${s3}&s4=${s4}&uid=${uid}&firstname=&lastname=&cta_bg=rgb(66 112 236)&cta_color=rgb(255 255 255)&progress_fill=rgb(66 112 236)`;

    
  const url = `https://useasycash.com/formcdn/v-1.2.1/?c=UserInteractive&m=UI_1_eloan&s1=${s1}&s2=${s2}&s3=${s3}&s4=${s4}&uid=${uid}&domain=loanfy.io&sub_id=FYLOAN&max_amount=10000&border_color=4270EC&checked_color=4270EC`;

  
  // rgba(65, 88, 208, 1)
  // rgba(59, 231, 127, 1)
  // console.log("QueryParas", s1, uid, utm_medium, s2);
 //test
  // add loadding
  const [setLoading] = useState(true);
  const spinner = document.getElementById("spinner");
  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      // debugger;
      setLoading(false);
    }, 2000);
  }

  const disp = {
    display:"none"
  }
  return (
    
    <>
      <Header disp={disp}/>
      <div style={{paddingTop:"70px"}}>
        <div
          className="container m-auto"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          
          }}
        >
          <div id="spinner">
            <div style={{ display: "flex", justifyContent: "center", paddingTop:"150px" }}>
              <img src={favimg} className="" alt="favicon"/>
            </div>
            {/* <div class="loading"></div> */}
            <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-3"></div>
            </div>
          </div>
        </div>

        <iframe title="Form" src={url} className="main-div" ref={observedDiv} />
      </div>
    </>
    // )
  );
};

export default Form;
