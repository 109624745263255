import React from 'react'
import { Container } from 'react-bootstrap'
import Footer from '../../sections/FooterBlock/Footer'
import Header from '../../sections/Header/Header'
import ReadyBlock from '../../sections/ReadyBlock/ReadyBlock'

const RateFees = (props) => {
    const { onSubmit ,eventTrack} = props;
    return (
        <>
            <Container fluid>
                <div className='container'>
                    <Header />

                    <div className='page_main'>
                        <h1 className='page_headding'>Rates and Fee</h1>
                        <p className='page_para'>
                            We’re focused on transparency so you know exactly where you stand at all times.
                        </p>

                        <div className='page_inner_main'>
                            <div className=''>

                                <h1 className='page_subheadding'>loanhubcenter.com has no control over loan rates</h1>
                                <p className='page_para'>The rates and fees pertaining to your funds will eventually be controlled by your lender. It is the objective of loanhubcenter.com to assure that you will deal with those lenders who comply with the Federal and state lending rules. Along the same lines, we give a lot of information to enable you to tackle any issues that may emerge with the lender you select.</p>

                            </div>

                            <div className='page_inner_main'>
                                <div className=''>

                                    <h1 className='page_subheadding'>There are repercussions of late payments or default payments</h1>
                                    <p className='page_para'>The most critical thing that you should comprehend as a borrower is an implication of making late installments on your credit. Although every lender whom we work with has its own particular terms and conditions for the advances they offer, however, there are some late-installment repercussions that you ought to know before entering into a loan contract with the lender.</p>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
                <div className='row'>
                    <ReadyBlock onSubmit={onSubmit} Click={eventTrack("Rate-Fees", "Click")}/>
                </div>
                <Footer />
            </Container>
        </>
    )
}

export default RateFees